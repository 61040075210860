import React from 'react';

//CSS
import {
  Container,
  Grid,
  Box,
} from '@material-ui/core';
import styles from './index.module.scss';

//HOC
import BasicLayout from '../layouts/basic_layout';

//COMPONENTS
import SearchBar from '../components/searchbar';
import Head from '../components/head';

//GRAPHQL
import { graphql, useStaticQuery } from 'gatsby';




const IndexPage = () => {

  const query = useStaticQuery(graphql`
      query{
      markdownRemark(frontmatter: {title: {eq: "Nyitólap"}}){
        frontmatter{
          title
        }
        html
      }
    }
  `)

  return (
    <BasicLayout>
      <Head title={query.markdownRemark.frontmatter.title} />
      <Grid
        className={styles.mainContainer}
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Box className={styles.topContainer} component="section">
          <Container className={styles.topContainerInner} maxWidth="lg">
            <div className={styles.searchBarWrapper}>
              <SearchBar />
            </div>
          </Container>
        </Box>
        <Box className={styles.pageContentContainer} component="section">
          <Container maxWidth="md">
            <div className={styles.textContentContainer} dangerouslySetInnerHTML={{ __html: query.markdownRemark.html }}></div>
          </Container>
        </Box>
      </Grid>
    </BasicLayout>
  )
}

export default IndexPage
